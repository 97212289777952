import React from "react"

import SEO from "../components/seo"

const Privacy = () => {
  return (
    <article className="article">
      <SEO title="Privacy Policy" />
      <h1>
        <strong>
          PRIVACY POLICY &ndash; COLLECTION, USE AND DISCLOSURE OF PERSONAL DATA
        </strong>
      </h1>
      <ol>
        <li>
          <strong> OUR COMMITMENT TO PERSONAL DATA PROTECTION</strong>
        </li>
      </ol>
      <p>
        We, Dobacor together with subsidiaries and/or associated companies, are
        committed to protecting your privacy. This Privacy Policy describes the
        types of personal data we collect from or about you, the purposes for
        which the personal data is collected, who we may disclose your personal
        data to and your rights to request access and correction of personal
        data.
      </p>
      <p>&nbsp;</p>
      <ol start="2">
        <li>
          <strong> COLLECTION OF PERSONAL DATA</strong>
        </li>
      </ol>
      <p>
        &ldquo;Personal Data&rdquo; refers to any information that relates to,
        describes, identifies or can be used, directly or indirectly, to
        identify an individual, such as name, date of birth, nationality,
        passport or other identification numbers, telephone numbers, email and
        postal addresses, credit and financial information, etc. The types of
        personal data we collect from you will depend on the specific types of
        services and/or information requested by you.
      </p>
      <p>
        By providing us with your personal data, you agree and accept the terms
        of this Privacy Policy. If you choose not to provide the personal data
        to us, we may not be able to render the relevant information or services
        requested by you.
      </p>
      <p>&nbsp;</p>
      <ol start="3">
        <li>
          <strong> PURPOSES FOR WHICH THE PERSONAL DATA IS COLLECTED</strong>
        </li>
      </ol>
      <p>
        Generally, we may use the personal data collected for the following
        purposes:-
      </p>
      <ul>
        <li>To respond to your enquiries;</li>
        <li>To verify your identity;</li>
        <li>
          To establish and maintain a business relationship with and provide
          services to you;
        </li>
        <li>
          To operate, administer and improve the user&rsquo;s experience of our
          website;
        </li>
        <li>
          To send you promotional materials, newsletters and other
          communications, from time to time;
        </li>
        <li>
          To perform data analysis including conducting surveys and marketing
          researches to identify market trend and preference;
        </li>
        <li>
          To facilitate compliance with relevant legal and regulatory
          requirements;
        </li>
        <li>
          To protect against, identify and prevent fraud, money-laundering,
          terrorist-financing, breach of confidence, cyber attack and other
          unlawful activity;
        </li>
        <li>
          To facilitate recruitment and other human resources related purposes;
          and
        </li>
        <li>
          For any other incidental or associated purposes relating to the above
          or any other purpose which you may from time to time agree.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start="4">
        <li>
          <strong> DISCLOSURE OF PERSONAL DATA TO THIRD PARTIES</strong>
        </li>
      </ol>
      <p>
        In general, we share your personal data with parties who may require
        such information to assist us with administering our business
        relationship with you. The parties to whom the data is transferred to
        may have places of business outside the United States, your personal
        data may therefore be transferred out of United States.
      </p>
      <p>&nbsp;</p>
      <p>
        Depending on the nature of our relationship with you, we may share your
        personal data with the following parties:-
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          Our subsidiaries and/or associated companies which we consider
          appropriate in connection with or in relation to the purposes referred
          to hereinabove and/or provision of services to you;
        </li>
        <li>
          Any third party service that provide services to us or on our behalf;
        </li>
        <li>
          Any third party service providers engaged by us to provide services
          for or in relation to this Website or to provide information
          technology support to us, and /or to conduct maintenance of our
          servers, data banks, data bases and data management system;
        </li>
        <li>
          Any third party representing you, such as your investment
          representative, financial planner or to other third parties at your
          direction/consent your agents and representatives;
        </li>
        <li>
          Potential purchasers/assignees as part of reorganisation, merger,
          joint venture, transfer or sale of our business; and/or
        </li>
        <li>
          The government or other law enforcement or regulatory authorities for
          the purpose of complying with any applicable laws or regulations or
          court orders or lawful requests or to protect our rights or
          properties.
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol start="5">
        <li>
          <strong> SAFEGUARDING YOUR PERSONAL DATA</strong>
        </li>
      </ol>
      <p>
        We are committed to protecting the personal data that we collect from
        you. We have implemented appropriate administrative, technical and
        physical safeguards and precautions to protect the server(s) that store
        your personal data as well as to protect the security, integrity,
        accuracy, privacy and completeness of the personal data we collect from
        you against accidental, unlawful or unauthorized destruction, loss,
        alteration, access, disclosure or use. Whilst we endeavour to protect
        your personal data, it is impossible to guarantee that data transmission
        over the internet or any other public network is 100% secure.
      </p>
      <p>
        We will only retain the personal data we collect from you for as long as
        necessary for the purpose(s) for which that information was collected,
        and to the extent permitted by applicable laws. We take steps to ensure
        that when we no longer need to use your personal information, we will
        remove it from our systems and records and/or take steps to anonymize it
        so that you can no longer be identified from such information (save and
        except for the purpose of legal or regulatory compliance).
      </p>
      <p>&nbsp;</p>
      <ol start="6">
        <li>
          <strong> USE OF COOKIES</strong>
        </li>
      </ol>
      <p>
        We use cookies and similar technologies on our website to facilitate and
        improve your online experience. You may choose not to accept cookies
        from our website by changing your cookies setting. However, if you
        choose to disable cookies on your device, you may find that some
        features and functions of our website may not work properly.
      </p>
      <p>&nbsp;</p>
      <ol start="7">
        <li>
          <strong> LINKS TO OTHER WEBSITES</strong>
        </li>
      </ol>
      <p>
        Our website may contain hyperlinks and you may be directed to other
        websites which operate independently from us. This Privacy Policy does
        not apply to those other websites and they may send their own cookies to
        your device and may independently collect or solicit personal data from
        you. If you visit those other websites, you should consult their privacy
        policies before providing your personal data. We are not responsible in
        relation to any of your activities on those other websites.
      </p>
      <p>&nbsp;</p>
      <ol start="8">
        <li>
          <strong>
            {" "}
            RIGHTS TO REQUEST ACCESS AND CORRECTION OF PERSONAL DATA
          </strong>
        </li>
      </ol>
      <p>
        You have the right to request us to disclose the types of personal data
        we collect from you, the purpose(s) and source(s) of such personal data,
        and/or the third parties to whom we disclosed such personal data. You
        may also request to correct the personal data which you find outdated or
        incorrect.
      </p>
      <p>
        We may charge you a reasonable fee for processing your request and
        retrieving the requested data.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <ol start="9">
        <li>
          <strong> REVISIONS TO THIS PRIVACY POLICY</strong>
        </li>
      </ol>
      <p>
        We reserve the right to update and amend the terms of this Privacy
        Policy from time to time to reflect the changes in our legal and
        regulatory obligations without prior notice to you. The latest version
        of our Privacy Policy will be posted on our website. Please check for
        any updates regularly.
      </p>
      <p>&nbsp;</p>
      <ol start="10">
        <li>
          <strong> HOW TO CONTACT US</strong>
        </li>
      </ol>
      <p>
        We have appointed a privacy officer to ensure compliance with this
        Privacy Policy and applicable privacy legislation. If you have any
        questions or complaints about the application of this policy, please
        email us at <a href="mailto:info@dobacor.com">info@dobacor.com</a>.
      </p>
    </article>
  )
}

export default Privacy
